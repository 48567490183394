const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      // borderRadius: '16px!important',
      width: '100%',
    },
    '& .MuiDialogContent-root': {
      paddingX: 2,
    },
  },
  contentTitle: {
    display: 'flex',
    p: 2,
    pb: 1,
    justifyContent: 'flex-end',
  },
  closeButton: {
    color: 'text.black',
    width: 40,
    height: 40,
    mt: -1,
    mr: -1,
    border: 1,
    borderColor: 'background.black',
    zIndex: 999,
  },
  borderBotton: { borderBottom: ' 1px solid #E9E9E9' },
  borderTop: { borderTop: ' 1px solid #E9E9E9' },
  closeIcon: { fontSize: 20 },
  contentBody: {
    pt: 0,
    '& p:not(.MuiTypography-root)': {
      fontSize: 12,
    },
    '& li': {
      fontSize: 12,
    },
  },
  title: {
    width: '100%',
  },
}

export default styles
